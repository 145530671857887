//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Member from '@/components/Member.vue'
import { exportFile } from 'quasar'
import Inviter from '@/components/Inviter'

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')

  return `"${formatted}"`
}

export default {
    name: "EventMembers",
    components: {
      // Member,
      Inviter
    },
    data() {
      return {
        showInviter: false,
        searchText: '',
        page: 1,
        isLoading: true,
        members: [],
        pagination: null,
        columns: [
          {name: 'name',label: 'Name' ,format: val => `${val}`,sortable:true,align:'left',field: row => row.name,},
          {name: 'status', label:'Status',field: row => row.status,}
        ]
      }
    },
    methods:{
      fetchMembers(){
        this.isLoading = true;
        this.payload = {
          id: this.entityId,
          page: this.page,
          q: this.searchText,
        }
        this.$store.dispatch('event/fetchMembers',this.payload)
        .then((r) => {
          if(r.status == 'success'){
            r.participants.map((participant) =>{
              this.members.push({
                name:participant.first_name+' '+participant.last_name,
                status: participant.paid == '0' ? 'Unpaid' : 'Paid'
              })
            })
            this.pagination = r.pagination
            this.isLoading = false;
          }
        })
      },

      UpdatePaidStatus(){
        this.payload = {
          id: this.entityId,
          user_id: this.$store.state.user.id,
          status: '1'
        }
        this.$store.dispatch('event/UpdatePaidStatus',this.payload)
        .then((r) => {
          if (r.status == 'success'){
            this.$q.notify({
              message: 'You have marked yourself as paid for this event.',
              color:'success',
              icon: 'fact_check'
            });
            setTimeout(() => { this.$router.go(this.$router.currentRoute) }, 2000);
          }
        })
      },
       exportTable () {
        // naive encoding to csv format

        const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
          this.members.map(row => this.columns.map(col => wrapCsvValue(
            typeof col.field === 'function'
              ? col.field(row)
              : row[col.field === void 0 ? col.name : col.field],
            col.format
          )).join(','))
        ).join('\r\n')

        const status = exportFile(
          'event-participants.csv',
          content,
          'text/csv'
        )

        if (status !== true) {
          this.$q.notify({
            message: 'Browser denied file download...',
            color: 'negative',
            icon: 'warning'
          })
        }
      }
    },
    props: ['entityName', 'entityId', 'isMember', 'isAdmin', 'isOwner'],
    created(){
      this.fetchMembers()
    },
    
}
